<template>
  <div id="bg-image">
    <!-- 背景 -->
    <div id="bg-image-filter">
    </div>
  </div>
  <div class="container">
    <router-view />
    <!-- <router-link to="/">Top</router-link> -->
    <Copyright />
  </div>
</template>

<script>
import Copyright from "@/components/Copyright.vue";

export default {
  name: "App",
  components: {
    Copyright,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #c8c8c8; */
  /* color: #fbd; */
  /* text-shadow:   0 0 12px #fbd; */
  /* text-shadow:   4 0 12px #9fa; */
  /* text-shadow:   0 0 12px #acf; */
  color: #edd;
  text-shadow: 0 0 12px #f9a;

  margin-top: 0;
  padding-top: 60px;
}


#bg-image {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: #333;
  ;
  background-image: url(~@/assets/background-mq.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: -2;

}

#bg-image-filter {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(30, 30, 30, 0.5);
  ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: -1;

}

img {
  /* filter: drop-shadow(0 0 12px #f9a); */
}


a {
  color: #ccc;
}
</style>
