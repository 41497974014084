<template>
  <div class="valid-w3c">


    <img alt="怒るJenkinsおじさん" src="@/assets/jenkins-ojisan-oko.png" />
    <p>Built by Jenkins</p>


    <p>
      <a href="https://validator.w3.org/nu/?doc=https%3A%2F%2Fwww.gretel-net.tokyo%2F" target="_blank" rel="noopener">
        <img alt="valid-html" src="@/assets/valid-w3c/html.png" />
      </a>

      <a href="https://jigsaw.w3.org/css-validator/validator?uri=https%3A%2F%2Fwww.gretel-net.tokyo%2F" target="_blank"
        rel="noopener">
        <img alt="valid-css" src="@/assets/valid-w3c/css.png" />
      </a>

    </p>
  </div>
</template>

<script>
export default {
  name: "ValidW3C"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

.valid-w3c img {
  height: 2.5em;
}

.valid-w3c img {
  height: 2.5em;
}
</style>
