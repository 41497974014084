<template>
  <div class="index">


    <div class="honnnin-icon">
      <span class="dagger">†</span>
      <img alt="logo" src="@/assets/honnnin.png" />
      <span class="dagger">†</span>
    </div>

    <div class="gretchi-sign">
      <img alt="ぐれちのサイン" src="@/assets/gretchi-sign.png" />
    </div>

    <div class="internet-name">
      <h2>*ぐれち･ぐれたそ*</h2>
    </div>


    <div class="internet-yamero">
      <img alt="インターネットやめろ" src="@/assets/internet-yamero.png" />
    </div>



    <Welcome />

    <Profile />

    <Links />

    <ValidW3C />


  </div>
</template>

<script>
import Links from "@/components/Links.vue";
import Profile from "@/components/Profile.vue";
import ValidW3C from "@/components/ValidW3C.vue";
import Welcome from "@/components/Welcome.vue";

export default {
  name: "Index",
  props: {},
  components: {
    Welcome,
    Links,
    Profile,
    ValidW3C,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  bottom: 0;
}

.internet-name {
  margin-top: 2em;
}

.internet-name h2 {
  max-width: 100%;
}

.top-logo img {
  width: 100%;
  max-width: 300px;
}



.honnnin-icon {
  margin-top: 1em;
}

.honnnin-icon img {
  padding: 1em;
  width: 12em;
  max-width: 80%;
  border-radius: 50%;
  filter: drop-shadow(0 0 12px #f9a);
}

.honnnin-icon .dagger {
  font-size: 2em;
}


.gretchi-sign img {
  height: 4em;
  filter: drop-shadow(0 0 12px #f9a);
}


.internet-yamero {
  padding-top: 1.5em;
  /* padding-bottom: 1em; */
  text-align: center;
}

.internet-yamero img {
  height: 1.8em;
  ;
  max-width: 80%;
  filter: drop-shadow(0 0 12px #f9a);

}
</style>
