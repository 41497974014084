<template>
  <div class="profile">
    <h3>Profile</h3>
    <p>
      承認欲求に溺れた悲しきインターネット世界のモンスター😇<br>
      顔に見合った✨🎀可愛い発言🎀✨が出来るよう心がけています<br>
      今日も顔と頭と育ちの良さで生きてます🦄
    </p>

  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
