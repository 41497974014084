<template>
  <div class="about">
    <p class="top-logo">
      <img alt="top banner" src="@/assets/gn_hw_logo.png" />
    </p>

    <h3>About</h3>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <p>このWebサイトには大した意味も目的もありません｡</p>
        <p>
          とある事情でNGINXを上げたものの<code>/</code>がForbiddenってのもなんか味気ないのでページを用意しました｡
        </p>
        <p>人物像はTwitterとトップの写真が全てを物語ってます｡</p>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  bottom: 0;
}
</style>
