<template>
  <div class="copyright">
    <p>Copyright © 2022-2023 Gretel.net All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  bottom: 0;
  margin-top: 1em;
}
</style>
