<template>
  <div class="welcome">
    <h2>Welcome</h2>
    <p>ようこそ Gretel.net ポータルへ</p>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  bottom: 0;
}
</style>
